<template>
    <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('externalTraining.training_schedule') }} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:searchBody>
          <b-row>
            <b-col sm="12">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset">
                  <b-row>
                    <b-col sm="6">
                      <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                          </template>
                          <v-select
                              v-model="search.fiscal_year_id"
                              label="text"
                              :reduce="item => item.value"
                              :options= fiscalYearList
                              :placeholder="$t('globalTrans.select')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                          </v-select>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="6">
                      <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="circular_memo_no"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="search.circular_memo_no"
                              :options="circularList"
                              id="circular_memo_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-overlay :show="loadingSearch">
                    <b-row>
                      <b-col lg="6" sm="12">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('elearning_config.organization')"
                            label-for="organization"
                        >
                          <b-form-select
                              plain
                              v-model="search.org_id"
                              :options="orgList"
                              id="org_id"
                              disabled
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Batch No" vid="batch_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="batch_id"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.batch')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="search.batch_id"
                                      :options="batchList"
                                      id="batch_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col sm="12" md="6">
                          <ValidationProvider name="Training Type"  vid="training_type_id">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_type_id"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                              {{$t('elearning_config.training_type')}}
                              </template>
                              <b-form-select
                              plain
                              v-model="search.training_type_id"
                              :options="trainingTypeList"
                              id="training_type_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              disabled
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col sm="12" md="6">
                          <ValidationProvider name="Training Title" vid="training_title_id">
                              <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_title_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('elearning_config.training_title')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.training_title_id"
                                  :options="trainingTitleList"
                                  id="training_title_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col>
                          <b-button size="sm" variant="primary" type="submit">
                              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                          </b-button>
                      </b-col>
                    </b-row>
                  </b-overlay>
                </b-form>
              </ValidationObserver>
            </b-col>
          </b-row>
        </template>
      </card>
      <body-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('externalTraining.training_schedule') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
              <b-button variant="primary" @click="pdfExportList" class="mr-2">
                  {{  $t('globalTrans.export_pdf') }}
              </b-button>
          </template>
          <template v-slot:body>
              <b-overlay :show="loadingState">
                  <!-- <div style="border: 2px solid;margin:10px;padding:10px"> -->
                      <b-row v-if="search.org_id">
                          <b-col>
                              <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="search.org_id">
                                  <template v-slot:projectNameSlot>
                                  {{ }}
                                  </template>
                                  {{ $t('externalTraining.training_schedule') }}
                              </list-report-head>
                          </b-col>
                      </b-row>
                      <b-row v-if="routines.length>0">
                          <b-col lg="12" sm="12" class="p-0">
                              <b-table-simple bordered>
                                  <b-tr>
                                  <b-th style="width:35%;" scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_config.fiscal_year') }}</b-th>
                                  <b-td style="width:65%%;" class="pt-2 pb-2 text-left bg-light">{{ $i18n.locale === 'bn' ? search.fiscal_year_bn : search.fiscal_year }}</b-td>
                                  </b-tr>
                                  <b-tr>
                                  <b-th scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_config.organization') }}</b-th>
                                  <b-td  class="pt-2 pb-2 text-left bg-light">{{ $i18n.locale === 'bn' ? search.org_bn : search.org }}</b-td>
                                  </b-tr>
                                  <b-tr>
                                  <b-th style="width:35%;" scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('externalTraining.circular_memo_no') }}</b-th>
                                  <b-td style="width:65%%;" class="pt-2 pb-2 text-left bg-light">{{ search.circular_memo_no }}</b-td>
                                  </b-tr>
                                  <b-tr>
                                  <b-th scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_config.training_type') }}</b-th>
                                  <b-td  class="pt-2 pb-2 text-left bg-light">{{ $i18n.locale === 'bn' ? search.training_type_bn : search.training_type }}</b-td>
                                  </b-tr>
                                  <b-tr>
                                  <b-th scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_config.training_title') }}</b-th>
                                  <b-td  class="pt-2 pb-2 text-left bg-light">{{ $i18n.locale === 'bn' ? search.training_title_bn : search.training_title }}</b-td>
                                  </b-tr>
                                  <b-tr>
                                      <b-th scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_config.batch') }}</b-th>
                                      <b-td  class="pt-2 pb-2 text-left bg-light">{{ getBatchName(search.batch_id) }}</b-td>
                                  </b-tr>
                                  <b-tr>
                                      <b-th scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('globalTrans.date') }}</b-th>
                                      <b-td  class="pt-2 pb-2 text-left bg-light">{{ date }}</b-td>
                                  </b-tr>
                                  <b-tr>
                                      <b-th scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_tpm.training_fee') }}</b-th>
                                      <b-td  class="pt-2 pb-2 text-left bg-light">{{ $n(training_fee) }}</b-td>
                                  </b-tr>
                              </b-table-simple>
                          </b-col>
                      </b-row>
                      <b-row>
                      <b-col lg="12" sm="12" v-if="routines.length>0">
                          <h6 class="text-center text-dark reportTitle mb-1 mt-2" style="font-weight:bold;">{{ $t('elearning_tpm.training_schedule') }}</h6>
                        <slot v-if="routines.length>0">
                          <div v-for="(routine,index) in routines" :key="index">
                            <b-row class="pl-2 pr-2">
                              <b-col lg="6" sm="6" class="text-left pl-10">
                                <b>{{ $t('globalTrans.day') }}-{{$n(index+1)}}</b>
                              </b-col>
                              <b-col lg="6" sm="6"  class="text-right pr-10">
                                <b>{{routine.date | dateFormat}} ({{dayGetName(routine.date)}})</b>
                              </b-col>
                              <b-table-simple bordered>
                                <b-tr class="bg-primary text-white">
                                  <b-th style="width:30%" class="pt-2 pb-2 text-center">{{ $t('globalTrans.time') }}</b-th>
                                  <b-th style="width:25%" class="pt-2 pb-2 text-center">{{$t('elearning_config.topics_session')}}</b-th>
                                  <b-th style="width:20%" class="pt-2 pb-2 text-center">{{$t('elearning_config.speaker_facilitator')}}</b-th>
                                  <b-th style="width:25%" class="pt-2 pb-2 text-center">{{$t('elearning_config.vanue_name')}}</b-th>
                                </b-tr>
                                <template v-for="sheduleData in routine.time">
                                  <b-tr v-for="(schedule,index1) in sheduleData.list" :key="index1">
                                    <b-td v-if="index1 == 0" :rowspan="sheduleData.list.length" class="pt-2 pb-2 text-center">
                                      {{schedule.start_time | time12Formate}} - {{schedule.end_time | time12Formate}}
                                    </b-td>
                                    <b-td v-if="index1 == 0" :rowspan="sheduleData.list.length" >
                                      {{ $i18n.locale === 'bn' ? schedule.topics_session_bn : schedule.topics_session }}
                                    </b-td>
                                    <b-td class="pt-2 pb-2 text-center">
                                      {{ $i18n.locale === 'bn' ? schedule.speaker_bn : schedule.speaker }}
                                    </b-td>
                                    <b-td class="pt-2 pb-2 text-center" v-if="index1 == 0" :rowspan="sheduleData.list.length" >
                                      {{ $i18n.locale === 'bn' ? schedule.venue_bn : schedule.venue }}
                                    </b-td>
                                  </b-tr>
                                </template>
                              </b-table-simple>
                            </b-row>
                          </div>
                        </slot>
                      </b-col>
                    </b-row>
                    <b-row v-if="routines.length>0">
                      <b-col lg="12" sm="12">
                        <h6 class="text-center text-dark reportTitle mb-3" style="font-weight:bold;">{{ $t('elearning_tpm.course_coordinator') }}</h6>
                        <b-table-simple bordered>
                          <b-tr class="bg-primary text-white">
                            <b-th style="width:10%" class="pt-2 pb-2 text-center">{{ $t('globalTrans.name') }}</b-th>
                            <b-th style="width:10%" class="pt-2 pb-2 text-center">{{$t('globalTrans.designation')}}</b-th>
                            <b-th style="width:10%" class="pt-2 pb-2 text-center">{{$t('globalTrans.mobile_no')}} / {{ $t('globalTrans.email') }}</b-th>
                          </b-tr>
                          <b-tr v-for="(committee,index2) in committee.details" :key="index2">
                            <b-td class="pt-2 pb-2 text-center">{{ $i18n.locale === 'bn' ? committee.name_bn : committee.name_en }}</b-td>
                            <b-td class="pt-2 pb-2 ">
                              {{ $i18n.locale === 'bn' ? committee.designation_bn : committee.designation_en }}
                            </b-td>
                            <b-td class="pt-2 pb-2 text-center">
                              {{ ($i18n.locale === 'bn' ? '০' : '0') + $n(committee.mobile_no, { useGrouping: false }) }} <br>
                              {{ committee.email }}
                            </b-td>
                          </b-tr>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                    <slot v-else>
                        <h4 class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</h4>
                    </slot>
                  <!-- </div> -->
              </b-overlay>
          </template>
      </body-card>
  </div>
  </template>
  <style>
  .tables-data th, .tables-data td {
    padding: 0px 0px 0px 10px !important;
  }
  .tables-data {
    margin-bottom: 0px !important
  }
  </style>
  <script>
  import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
  import { trainingScheduleList, circularNoList, trainingScheduleCommittee, trainingScheduleCircularData } from '../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  import ListReportHead from '@/components/custom/ExternalListReportHead.vue'
  import ExportPdf from './export_pdf_list'
  import { mapGetters } from 'vuex'
  import moment from 'moment'
  export default {
      mixins: [ModalBaseMasterList],
      components: {
          ListReportHead
      },
      data () {
          return {
            search: {
              batch_id: 0,
              fiscal_year_id: 0,
              circular_memo_no: 0,
              training_type_id: 0,
              org_id: 0,
              training_title_id: 0,
              registration_for: 0
            },
            circularLoading: false,
            loadingSearch: false,
            fiscal_year_id: '',
            training_fee: '',
            date: '',
            routines: [],
            committee: [],
            batchList: [],
            trainingCategoryList: [],
            trainingTitleList: [],
            circularList: [],
            org_id: 0,
            trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
            allData: []
          }
      },
      computed: {
        fiscalYearList: function () {
          return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
        },
        ...mapGetters({
          authUser: 'Auth/authUser'
        }),
        orgList: function () {
          return this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.status === 1)
        },
      //   orgList: function () {
      //     return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      //   },
        trainingTypeList: function () {
          return this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTypeList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('elearning_config.organization'), class: 'text-left' },
                { label: this.$t('elearning_config.training_title'), class: 'text-left' },
                { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-left' },
                { label: this.$t('globalTrans.lesson'), class: 'text-left' },
                { label: this.$t('elearning_tpm.course_module'), class: 'text-left' },
                { label: this.$t('globalTrans.date'), class: 'text-left' },
                { label: this.$t('globalTrans.start_time'), class: 'text-left' },
                { label: this.$t('globalTrans.end_time'), class: 'text-left' },
                { label: this.$t('elearning_tpm.trainer'), class: 'text-left' }
            ]

            let keys = []
            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'org_bn' },
                { key: 'training_title_bn' },
                { key: 'circular_memo_no' },
                { key: 'course_name_bn' },
                { key: 'course_module_bn' },
                { key: 'training_date' },
                { key: 'start_time' },
                { key: 'end_time' },
                { key: 'trainer_name_bn' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'org' },
                { key: 'training_title_en' },
                { key: 'circular_memo_no' },
                { key: 'course_name' },
                { key: 'course_module' },
                { key: 'training_date' },
                { key: 'start_time' },
                { key: 'end_time' },
                { key: 'trainer_name' }
                ]
            }
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
      },
      async created () {
          // this.getCircularList()
          this.search = Object.assign({}, this.search, {
              fiscal_year_id: this.$store.state.currentFiscalYearId
          })
      },
       mounted () {
      },
      watch: {
          'search.circular_memo_no': function (newValue) {
              this.getCircularMemoNo(newValue)
          },
          'search.fiscal_year_id': function (newValue) {
              this.getCircularList(newValue)
          },
          'search.training_type_id': function (newValue) {
              this.trainingTitleList = this.getTrainingTitleList(newValue)
          },
          'search.org_id': function (newValue) {
              this.batchList = this.getBatchList(newValue)
          }
      },
      methods: {
          async searchData () {
            this.loadData()
          },
          getBatchList (orgId) {
              const batchList = this.$store.state.ExternalUserService.trainingPanel.commonObj.batchList.filter(item => item.status === 1)
              if (orgId) {
              return batchList.filter(item => item.org_id === orgId)
              }
              return batchList
          },
          getBatchName (id) {
              const companyType = this.$store.state.ExternalUserService.trainingPanel.commonObj.batchList.find(obj => obj.value === parseInt(id))
              if (typeof companyType !== 'undefined') {
              return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
              } else {
              return ''
              }
          },
          async getCircularList (fiscalYearId) {
              this.circularLoading = true
              const params = {
                  fiscal_year_id: fiscalYearId
              }
              const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularNoList, params)
              if (!result.success) {
                  this.circularList = []
              } else {
                  const listObject = result.data
                  const tmpList = listObject.map((obj, index) => {
                      if (this.$i18n.locale === 'bn') {
                          return { value: obj.circular_memo_no, text: obj.circular_memo_no, training_type_id: obj.training_type_id, training_category_id: obj.training_category_id, training_title_id: obj.training_title_id, fiscal_year_id: obj.fiscal_year_id, org_id: obj.org_id, office_type_id: obj.office_type_id, batch_id: obj.batch_id }
                      } else {
                          return { value: obj.circular_memo_no, text: obj.circular_memo_no, training_type_id: obj.training_type_id, training_category_id: obj.training_category_id, training_title_id: obj.training_title_id, fiscal_year_id: obj.fiscal_year_id, org_id: obj.org_id, office_type_id: obj.office_type_id, batch_id: obj.batch_id }
                      }
                  })
                  this.circularList = tmpList
              }
              this.circularLoading = false
          },
          async getCircularMemoNo (getCircularMemoNo) {
              this.loadingSearch = true
              this.getCircularData(getCircularMemoNo)
              if (getCircularMemoNo) {
                   const trData = this.circularList.find(cir => cir.value === getCircularMemoNo)
                   if (typeof trData !== 'undefined') {
                      this.search.training_title_id = trData.training_title_id
                      this.search.org_id = trData.org_id
                      this.search.training_type_id = trData.training_type_id
                      const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(doc => doc.value === parseInt(trData.org_id))
                      if (typeof orgObj !== 'undefined') {
                          this.search.org = orgObj.text_en
                          this.search.org_bn = orgObj.text_bn
                      } else {
                          this.search.org = ''
                          this.search.org_bn = ''
                      }
                      const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(trData.fiscal_year_id))
                      if (typeof fiscalYearObj !== 'undefined') {
                          this.search.fiscal_year = fiscalYearObj.text_en
                          this.search.fiscal_year_bn = fiscalYearObj.text_bn
                      } else {
                          this.search.fiscal_year = ''
                          this.search.fiscal_year_bn = ''
                      }
                      const trainingTitleObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.find(doc => doc.value === parseInt(trData.training_title_id))
                      if (typeof trainingTitleObj !== 'undefined') {
                          this.search.training_title = trainingTitleObj.text_en
                          this.search.training_title_bn = trainingTitleObj.text_bn
                      } else {
                          this.search.training_title = ''
                          this.search.training_title_bn = ''
                      }
                      const trainingTypeObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTypeList.find(doc => doc.value === parseInt(trData.training_type_id))
                      if (typeof trainingTypeObj !== 'undefined') {
                          this.search.training_type = trainingTypeObj.text_en
                          this.search.training_type_bn = trainingTypeObj.text_bn
                      } else {
                          this.search.training_type = ''
                          this.search.training_type_bn = ''
                      }
                      const commiteeSearch = {
                          fiscal_year_id: trData.fiscal_year_id,
                          org_id: trData.org_id
                      }
                      RestApi.getData(trainingElearningServiceBaseUrl, trainingScheduleCommittee, commiteeSearch).then(response => {
                          if (response.success) {
                          this.committee = response.data
                          this.circularLoading = false
                          } else {
                          this.circularLoading = false
                          }
                      })
                   } else {
                      this.search.training_title_id = ''
                      this.search.training_type_id = ''
                      this.search.org_id = ''
                      this.search.training_category = ''
                      this.search.training_category_bn = ''
                      this.search.training_type = ''
                      this.search.training_type_bn = ''
                      this.search.training_title = ''
                      this.search.training_title_bn = ''
                      this.search.fiscal_year = ''
                      this.search.fiscal_year_bn = ''
                      this.search.org = ''
                      this.search.org_bn = ''
                   }
              }
              this.loadingSearch = false
          },
          getCategoryList (typeId) {
              const trainingCategoryList = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingCategoryList.filter(item => item.status === 1)
              if (typeId) {
                  return trainingCategoryList.filter(item => item.training_type_id === typeId)
              }
              return trainingCategoryList
          },
          getTrainingTitleList (categoryId) {
              const trainingTitleList = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.filter(item => item.status === 1)
              if (categoryId) {
                  return trainingTitleList.filter(item => item.training_type_id === categoryId)
              }
              return trainingTitleList
          },
          dayGetName (value) {
              return moment(value).format('dddd')
          },
          loadData () {
              if (this.search.circular_memo_no) {
                  const params = {
                      circular_memo_no: this.search.circular_memo_no,
                      training_type_id: this.search.training_type_id,
                      batch_id: this.search.batch_id,
                      org_id: this.search.org_id,
                      training_title_id: this.search.training_title_id,
                      registration_for: this.$store.state.ExternalUserService.trainingPanel.panel
                  }
                  this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
                  RestApi.getData(trainingElearningServiceBaseUrl, trainingScheduleList, params).then(response => {
                      if (response.success) {
                          this.routines = response.data
                      }
                      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                  })
              }
          },
        async getCircularData (circularMemoNo) {
            this.load = true
            const params = {
                circular_memo_no: circularMemoNo
            }
            RestApi.getData(trainingElearningServiceBaseUrl, trainingScheduleCircularData, params).then(response => {
                if (response.success) {
                this.training_fee = response.data.registration_fee
                const startDate = response.data.training_start_date.split('-')
                const endDate = response.data.training_end_date.split('-')
                let startMonthId = ''
                let endMonthId = ''
                startMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(startDate[1]))
                endMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(endDate[1]))
                if (startDate[1] === endDate[1]) {
                    this.date = this.$n(startDate[2]) + ' - ' + this.$n(endDate[2]) + ' ' + (this.$i18n.locale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
                } else {
                    this.date = this.$n(startDate[2]) + ' ' + (this.$i18n.locale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ' - ' + this.$n(endDate[2]) + ' ' + (this.$i18n.locale === 'en' ? endMonthId.text_en : endMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
                }
                this.load = false
                } else {
                this.load = false
                }
            })
        },
          getCustomDataList (data) {
            const listData = data.map(item => {
            const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(doc => doc.value === parseInt(item.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
                orgData.org = orgObj.text_en
                orgData.org_bn = orgObj.text_bn
            } else {
                orgData.org = ''
                orgData.org_bn = ''
            }
            return Object.assign({}, item, orgData)
            })
            return listData
          },
          pdfExportList () {
            const reportTitle = this.$t('elearning_tpm.routine')
            ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', this.search.org_id, reportTitle, this.search, this, this.routines, this.committee)
          }
      }
  }
  </script>
